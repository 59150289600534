import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { push } from '../../../client/history'
import getAsset from '../../../../platform/getAsset'
import { AppContext } from '../create-app'
import { ControlTree } from '../../../client/control-tree/types'

// Why two layers of divs in the card? As to create a padding shell around the
// card, without making that empty space trigger hover or handle clicks. Why
// not CSS gap? Because we want to handle scroll events between the cards and a
// little bit around them. The gap belongs to the parent, not the cards, so if
// we disable the pointer events on the parent, there will be no scrolling. Why
// do we not just let the whole screen be scrollable? The Z of the product
// selector is higher than that of the toolbar, but we still want the visible
// parts of the logo and the Spectacle badge to be clickable.

const ProductCard = ({ name, id }) => {
  return (
    <div className="pointer-events-auto p-[8px]">
      <div
        className="group flex h-[320px] w-[256px] cursor-pointer flex-col rounded-[12px] border-grayLegacy bg-white py-[32px]"
        onClick={() => push({ path: `/sku/${id}` })}
      >
        <img
          className="pointer-events-none min-h-0 min-w-0 flex-1 object-contain transition-transform group-hover:scale-110"
          src={getAsset(`thumbnails/${id}-none.png`)}
        />
        <div className="z-10 text-center font-semibold">{name}</div>
      </div>
    </div>
  )
}

const ProductSelection = () => {
  const { controlTree: ct } = useContext(AppContext)
  const controlTree = ct as ControlTree
  const nodes = useSelector(controlTree.getNodes)
  return (
    <div className="pointer-events-none fixed h-screen w-full overflow-y-auto px-[24px]">
      <div className="flex min-h-full flex-wrap place-content-center items-center justify-center py-[136px] drop-shadow-[0_0_64px_#E6E6E6]">
        {nodes['sku'].visibleOptions!.map((product) => (
          <ProductCard key={product.id} name={product.name} id={product.id} />
        ))}
      </div>
    </div>
  )
}

export default ProductSelection
