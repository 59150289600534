// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { VendorRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "hostname": "claw.olv.app",
    "environment": "production",
    "sportId": "various",
    "vendorId": "claw",
    "vendorName": "Claw",
    "logoAsset": "logo.png",
    "homeUrl": "https://claw.pt/",
    "bakeryEmailTo": [
      "customizers@olv.global"
    ],
    "features": {
      "shareDesign": true,
      "factoryOutput": true,
      "autoRotation": true,
      "fabric": false,
      "design": true,
      "productColor": true,
      "colorTone": false,
      "designColor": true,
      "teamName": false,
      "playerName": true,
      "playerNumber": false,
      "outline": false,
      "brandLogo": false,
      "teamLogo": true,
      "fonts": {
        "google": "all"
      },
      "fill": false,
      "spectacleLogo": true,
      "fullCustom": false
    },
    "defaults": {
      "designColor1Id": "lightOrange",
      "designColor2Id": "red",
      "designColor3Id": "yellow",
      "teamName": {
        "text": "Claw",
        "font": "google:Maven Pro/600",
        "colorId": "black",
        "outline1ColorId": "white",
        "outline2ColorId": "black"
      },
      "playerName": {
        "font": "google:Norican/regular",
        "colorId": "black"
      },
      "playerNumber": {
        "font": "google:Graduate/regular",
        "colorId": "black",
        "outline1ColorId": "white",
        "outline2ColorId": "black"
      }
    }
  },
  {
    "hostname": "claw-staging.cdev.orangelv.com",
    "environment": "staging",
    "sportId": "various",
    "vendorId": "claw",
    "vendorName": "Claw",
    "logoAsset": "logo.png",
    "homeUrl": "https://claw.pt/",
    "bakeryEmailTo": [
      "customizers@olv.global"
    ],
    "features": {
      "shareDesign": true,
      "factoryOutput": true,
      "autoRotation": true,
      "fabric": false,
      "design": true,
      "productColor": true,
      "colorTone": false,
      "designColor": true,
      "teamName": false,
      "playerName": true,
      "playerNumber": false,
      "outline": false,
      "brandLogo": false,
      "teamLogo": true,
      "fonts": {
        "google": "all"
      },
      "fill": false,
      "spectacleLogo": true,
      "fullCustom": true
    },
    "defaults": {
      "designColor1Id": "lightOrange",
      "designColor2Id": "red",
      "designColor3Id": "yellow",
      "teamName": {
        "text": "Claw",
        "font": "google:Maven Pro/600",
        "colorId": "black",
        "outline1ColorId": "white",
        "outline2ColorId": "black"
      },
      "playerName": {
        "font": "google:Norican/regular",
        "colorId": "black"
      },
      "playerNumber": {
        "font": "google:Graduate/regular",
        "colorId": "black",
        "outline1ColorId": "white",
        "outline2ColorId": "black"
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<VendorRow[]>> = data;

export type Vendor = typeof typed[number];

export const VENDOR_INDEX_KEY = "hostname";
export type VendorIndexKey = "hostname";
export type VendorHostname = Vendor["hostname"];

let i = 0;
export const VENDOR_DICT = {
  "claw.olv.app": typed[i++],
  "claw-staging.cdev.orangelv.com": typed[i++]
} as const;

export { typed as VENDORS };
