import { useAppDispatch } from '../../../../platform/client/configureStore'
import { change } from '../../../../platform/client/control-tree'
import { AlertMessage } from './Alert'
import TextInput from './TextInput'
import TextStyleInput from './TextStyleInput'
import TileSection from './TileSection'
import TileSet from './TileSet'
import { FontNode, TextNode } from '../../../client/control-tree/types'

type Props = {
  title: string
  textNode: TextNode
  colorNode: any
  fontNode: FontNode
  outline1ColorNode: any
  outline2ColorNode: any
  setAlert: (alert: AlertMessage) => void
  isColorToneEnabled: boolean
  isOutlineEnabled: boolean
  scaleFactorNode?: any
  playerNumberFrontScaleFactorNode?: any
  playerNumberBackScaleFactorNode?: any
}

const TextTile = ({
  title,
  textNode,
  colorNode,
  fontNode,
  outline1ColorNode,
  outline2ColorNode,
  setAlert,
  isColorToneEnabled,
  isOutlineEnabled,
  scaleFactorNode,
  playerNumberFrontScaleFactorNode,
  playerNumberBackScaleFactorNode,
}: Props) => {
  const dispatch = useAppDispatch()

  return (
    <TileSection section={title}>
      <TileSet>
        <TextInput
          value={textNode.value || ''}
          onChanged={(value) => {
            dispatch(change(textNode.keyPath, value))
          }}
        />
      </TileSet>
      {(colorNode.isAvailable || fontNode.isAvailable) && (
        <TextStyleInput
          colorNode={colorNode}
          fontNode={fontNode}
          outline1ColorNode={outline1ColorNode}
          outline2ColorNode={outline2ColorNode}
          setAlert={setAlert}
          isColorToneEnabled={isColorToneEnabled}
          isOutlineEnabled={isOutlineEnabled}
          scaleFactorNode={scaleFactorNode}
          playerNumberFrontScaleFactorNode={playerNumberFrontScaleFactorNode}
          playerNumberBackScaleFactorNode={playerNumberBackScaleFactorNode}
        />
      )}
    </TileSection>
  )
}

export default TextTile
