import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { pathSelector } from '../../../client/common/selectors'
import { replace } from '../../../client/history'
import { useLocalStorage } from '../../../client/hooks'
import { AppContext } from '../create-app'
import Alert, { AlertMessage } from './Alert'
import ProductSelection from './ProductSelection'
import Sidebar from './Sidebar'
import Toolbar from './Toolbar'
import Viewer from './Viewer'
import { ControlTree } from '../../../client/control-tree/types'

const App = () => {
  const {
    controlTree: ct,
    vendor: { defaults },
  } = useContext(AppContext)
  const controlTree = ct as ControlTree

  const nodes: any = useSelector(controlTree.getNodes)
  const route = useSelector(pathSelector)
  const isSingleProduct = nodes['sku'].visibleOptions.length === 1
  const firstProductId = nodes['sku'].visibleOptions[0].id
  const isViewerMode = isSingleProduct || route !== '/'

  useEffect(() => {
    if (isSingleProduct && route === '/') {
      replace({ path: `/sku/${firstProductId}` })
    }
  }, [route, isSingleProduct, firstProductId])

  const [autoRotate, setAutoRotate] = useLocalStorage('autoRotate', true)
  const [skybox, setSkybox] = useLocalStorage('skybox', defaults?.skybox)
  const [alert, setAlert] = useState<AlertMessage>()
  const setAlertWithTimeout = (x: AlertMessage) => {
    setAlert(x)
    setTimeout(() => {
      setAlert(undefined)
    }, x.timeout ?? 3000)
  }

  return (
    <>
      {isViewerMode && <Viewer autoRotate={autoRotate} skybox={skybox} />}
      <div className="flex">
        <Toolbar
          autoRotate={autoRotate}
          setAutoRotate={setAutoRotate}
          setAlert={setAlertWithTimeout}
          showControls={isViewerMode}
          skybox={skybox}
          setSkybox={setSkybox}
        />
        <div className="pointer-events-none flex-grow" />
        {isViewerMode && <Sidebar setAlert={setAlertWithTimeout} />}
      </div>
      {!isViewerMode && <ProductSelection />}
      {alert && <Alert severity={alert.severity}>{alert.text}</Alert>}
    </>
  )
}

export default App
