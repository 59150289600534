import { MaterialConfig, RendererConfig } from '@orangelv/bjs-renderer'
import { Vector2 } from '@orangelv/utils-geometry'
import { createGetSize } from '@orangelv/utils-olvpf'
import { Immutable } from '@orangelv/utils'
import { svgToCanvas } from '@orangelv/utils-dom'

import getAsset from '../../../platform/getAsset'
import {
  loadSvg,
  loadFont,
  loadSize,
  loadPattern,
} from '../../client/svg-renderer-utils'
import { VIEWER_OFFSET } from '../../../../tailwind.config'
import { sharedMemo } from '../../common/shared-memo'
import { renderPieceFromRecipe } from '../common/svg-renderer-utils'
import { getSkyboxTexture } from './skybox'
import {
  ColorRow,
  DesignPartRow,
  FontRow,
  CustomFontRow,
  PlacementRow,
  Recipe,
  VendorRow,
  FillRow,
} from '../common/types'

function getAnchors({ commonAnchors }): Record<string, Vector2> {
  const armBottom =
    commonAnchors['armLeftBottom'] || commonAnchors['armRightBottom']
  if (!armBottom) return {}
  return {
    armBottomMiddle: {
      x: commonAnchors['document-center'].x,
      y: armBottom.y,
    },
  }
}

export const getSize = createGetSize(sharedMemo, loadSize, { getAnchors })

export type GetRendererConfigArgs = {
  autoRotate: boolean
  colorDict: Record<string, ColorRow>
  fillDict: Immutable<Record<string, FillRow>>
  customFonts: Immutable<CustomFontRow[]>
  designParts: Immutable<DesignPartRow[]>
  fonts: Immutable<FontRow[]>
  patternName: string
  patternPackage: string
  placements: Immutable<PlacementRow[]>
  playerBeingPreviewed: number
  recipe: Recipe
  skybox: undefined | string
  vendor: Immutable<VendorRow>
  hasFullUI?: boolean
}

export async function getRendererConfig({
  autoRotate,
  colorDict,
  fillDict,
  customFonts,
  designParts,
  fonts,
  patternName,
  patternPackage,
  placements,
  playerBeingPreviewed,
  recipe,
  skybox,
  vendor,
  hasFullUI,
}: GetRendererConfigArgs): Promise<RendererConfig> {
  const pattern = await loadPattern(patternPackage, patternName)
  const sizeName = pattern.sizeNames[0] // Until we have more than one

  const normalTexture =
    recipe['fabric'] ?
      { url: getAsset(`normals/${recipe['fabric']}.png`) }
    : undefined

  const player = {
    name: recipe[`players.roster.${playerBeingPreviewed}.name`] ?? '',
    number: recipe[`players.roster.${playerBeingPreviewed}.number`] ?? '',
  }

  const materials = Object.fromEntries(
    await Promise.all(
      pattern.pieceMapping.map(
        async (pieceMappingItem): Promise<[string, MaterialConfig]> => {
          const svg = await renderPieceFromRecipe({
            patternPackage,
            recipe,
            pattern,
            patternName,
            pieceMappingItem,
            sizeName,
            player,
            getSize,
            loadSvg,
            loadFont,
            colorDict,
            fillDict,
            designParts,
            fonts,
            customFonts,
            placements,
            vendor,
          })

          return [
            `${patternName}_${pieceMappingItem.name}_mat`,
            {
              diffuseColor: '#ffffff',
              diffuseTexture: {
                key: svg,
                getCanvas: (canvas) => svgToCanvas(svg, { canvas }),
              },
              normalTexture,
            },
          ]
        },
      ),
    ),
  )

  return {
    scene: {
      environmentTexture: getAsset('environmentSpecular.env'),
      skyboxTexture: skybox ? getSkyboxTexture(skybox) : undefined,
    },
    camera: {
      lowerRadius: 0.5,
      upperRadius: 1.5,
      lowerBeta: Math.PI / 2 - Math.PI / 4,
      upperBeta: Math.PI / 2 + Math.PI / 4,
      peek: { yFactor: 1 / 3 },
      autoRotate,
      translateProjection: { x: hasFullUI ? VIEWER_OFFSET : 0 },
    },
    models: {
      main: {
        url: getAsset(`models/${patternName}.gltf`),
        materials: {
          ...materials,
          [`${patternName}_inner_mat`]: { diffuseColor: '#ffffff' },
        },
      },
    },
  }
}
