import cn from 'classnames'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '../../../client/configureStore'
import { change } from '../../../client/control-tree'
import getAsset from '../../../getAsset'
import { AppContext } from '../create-app'
import { AlertMessage } from './Alert'
import ColorTile from './ColorTile'
import ImageTileDouble, { EmptyImageTileDouble } from './ImageTileDouble'
import ImageTileTriple from './ImageTileTriple'
import ImageUpload from './ImageUpload'
import RosterTile from './RosterTile'
import TextTile from './TextTile'
import TileSection from './TileSection'
import TileSet from './TileSet'
import SelectTile from './SelectTile'
import _ from 'lodash'
import { MAX_DESIGN_COLORS } from '../../common/consts.js'
import {
  ColorOption,
  ControlTree,
  FillOption,
  FontNode,
  SkuNode,
  TextNode,
} from '../../../client/control-tree/types'
import { FillRow } from '../../common/types'

// Why is this needed? To catch the pointer events only around the controls,
// not the section titles (and leave those for the viewer instead).
const Filler = ({ className }: { className: string }) => (
  <div
    className={cn(
      'pointer-events-auto box-content w-sidebar flex-none self-end px-[32px]',
      className,
    )}
  />
)

const Sidebar = ({ setAlert }: { setAlert: (_: AlertMessage) => void }) => {
  const {
    controlTree: ct,
    vendor: { features },
  } = useContext(AppContext)
  const controlTree = ct as ControlTree
  const nodes = useSelector(controlTree.getNodes)

  const dispatch = useAppDispatch()

  const fabricNode = nodes['fabric']
  const fabricOptions =
    fabricNode.isAvailable ?
      ((fabricNode.visibleOptions || fabricNode.options) ?? [])
    : []

  const designNode = nodes['design.design']
  const designOptions =
    designNode.isAvailable ?
      [{ id: null as string | null, name: 'None' }].concat(
        designNode.visibleOptions || designNode.options || [],
      )
    : []

  const availableColorNodes = [
    { node: nodes['design.color1'], label: 'Color 1' },
    { node: nodes['design.color2'], label: 'Color 2' },
    { node: nodes['design.color3'], label: 'Color 3' },
    { node: nodes['design.color4'], label: 'Color 4' },
  ].filter(({ node }) => node?.isAvailable)

  const designFileNode = nodes[
    'design.file'
  ] as (typeof nodes)['design.file'] & { value: { id: string } }

  const brandLogoColorNode = nodes['brandLogoColor']

  const skuNode = nodes['sku'] as SkuNode

  const productId = skuNode.value
  const isRosterEnabled = skuNode.object.isRosterEnabled
  const productColorAreas = skuNode.object.colorAreas

  const [productColorNodes, productFillNodes] = productColorAreas.reduce<
    [(typeof productColorNodes)[number][], (typeof productFillNodes)[number][]]
  >(
    ([colorNodes, fillNodes], area) => {
      const colorNode = nodes[`${area}Color`]
      const fillNode = nodes[`${area}.fill`]
      const fillColorNodes = Array.from(
        { length: MAX_DESIGN_COLORS },
        (_, j) => ({
          label: `Color ${j + 1}`,
          node: nodes[`${area}.color${j + 1}`],
        }),
      )

      const updatedColorNodes = [...colorNodes]
      if (colorNode?.isAvailable) updatedColorNodes.push(colorNode)

      const updatedFillNodes = [...fillNodes]
      if (fillNode?.isAvailable) {
        updatedFillNodes.push({
          label: _.startCase(area + ' fill'),
          node: fillNode,
          colors: fillColorNodes,
        })
      }

      return [updatedColorNodes, updatedFillNodes]
    },
    [[], []],
  )

  const showPlainColorsSection = !features.fill && productColorNodes.length > 0

  const isSomeFillColorAvailable = (
    fillNode: (typeof productFillNodes)[number],
  ) => fillNode.colors.some((colorNode) => colorNode.node.isAvailable)
  const getSelectedFillProps = (fillNode: (typeof productFillNodes)[number]) =>
    (
      fillNode.node.options!.find(
        (option) => option.id === fillNode.node.value,
      ) as FillRow
    ).props

  const teamNameTextNode = nodes['teamName.text']

  const teamLogoNode = nodes['teamLogo']
  const teamLogoNodeValue = teamLogoNode.value as { id: string } | undefined
  const teamLogoImageUrl =
    teamLogoNodeValue ? `/api/images/${teamLogoNodeValue.id}` : undefined

  const playerSize = nodes['players.roster.1.size']

  return (
    <div className="pointer-events-none hidden h-full flex-col text-lg drop-shadow-[0_0_64px_#E6E6E6] fullUI:flex">
      <Filler className="h-[32px]" />

      {fabricNode.isAvailable && (
        <TileSection section="Fabric">
          <TileSet>
            {fabricOptions.map((fabric) => (
              <ImageTileTriple
                key={fabric.id}
                path={getAsset(`icons/fabric/${fabric.id}.png`)}
                title={fabric.name}
                isSelected={fabricNode.value === fabric.id}
                onSelected={() => {
                  dispatch(change(fabricNode.keyPath, fabric.id))
                }}
              />
            ))}
          </TileSet>
        </TileSection>
      )}

      {designNode.isAvailable && designOptions.length > 1 && (
        <TileSection section="Design">
          <TileSet>
            {designOptions.map((design) => (
              <ImageTileDouble
                key={design.id}
                isSelected={nodes['design.design'].value === design.id}
                onSelected={() => {
                  dispatch(change(designNode.keyPath, design.id))
                }}
                title={design.name}
                path={getAsset(
                  `thumbnails/${productId}-${design.id ?? 'none'}.png`,
                )}
              />
            ))}
            {designOptions.length % 2 ?
              <EmptyImageTileDouble />
            : null}
          </TileSet>

          {availableColorNodes.length > 0 && (
            <TileSet>
              {availableColorNodes.map(({ node, label }) => (
                <ColorTile
                  key={label}
                  label={label}
                  colors={node.options as ColorOption[]}
                  color={node.object as ColorOption}
                  onSelected={(color) => {
                    dispatch(change(node.keyPath, color ? color.id : null))
                  }}
                  setAlert={setAlert}
                  isColorToneEnabled={features.colorTone}
                />
              ))}
            </TileSet>
          )}
        </TileSection>
      )}

      {designFileNode.isAvailable && (
        <TileSection section="Full Custom">
          <TileSet>
            <ImageUpload
              imageUrl={
                designFileNode.value ?
                  `/api/images/${designFileNode.value.id}`
                : undefined
              }
              accept="image/svg+xml"
              onChanged={(file) => {
                dispatch(change(designFileNode.keyPath, file))
              }}
              onRemoved={() => {
                dispatch(change(designFileNode.keyPath, null))
              }}
              setAlert={setAlert}
              kindOfFile="your design"
            />

            <div className="w-sidebar rounded-b-[12px] bg-white px-[16px] py-[12px] text-sm">
              Download the template (
              <a
                className="text-orange hover:underline"
                href={getAsset(
                  `full-custom-templates/${productId}-template.svg`,
                )}
                download={`${productId}-template.svg`}
                target="_blank"
                rel="noreferrer"
              >
                {productId}-template.svg
              </a>
              ), open it in a vector graphics editor like Adobe Illustrator or{' '}
              <a
                className="text-orange hover:underline"
                href="https://inkscape.org/"
                target="_blank"
                rel="noreferrer"
              >
                Inkscape
              </a>{' '}
              (free) and follow the instructions inside the file.
            </div>
          </TileSet>
        </TileSection>
      )}

      {(brandLogoColorNode.isAvailable || showPlainColorsSection) && (
        <TileSection section="Colors">
          {brandLogoColorNode.isAvailable && (
            <TileSet>
              <ColorTile
                label="Logo"
                colors={brandLogoColorNode.options as ColorOption[]}
                color={brandLogoColorNode.object as ColorOption}
                onSelected={(color) => {
                  dispatch(
                    change(brandLogoColorNode.keyPath, color ? color.id : null),
                  )
                }}
                setAlert={setAlert}
                isColorToneEnabled={features.colorTone}
              />
            </TileSet>
          )}

          {showPlainColorsSection && (
            <TileSet>
              {productColorNodes.map((colorNode, i) => (
                <ColorTile
                  key={colorNode.keyPath}
                  label={productColorAreas[i]}
                  colors={colorNode.options as ColorOption[]}
                  color={colorNode.object as ColorOption}
                  onSelected={(color) => {
                    dispatch(change(colorNode.keyPath, color ? color.id : null))
                  }}
                  setAlert={setAlert}
                  isColorToneEnabled={features.colorTone}
                />
              ))}
            </TileSet>
          )}
        </TileSection>
      )}

      {features.fill &&
        productFillNodes.map((fillNode, i) => (
          <TileSection
            section={fillNode.label}
            key={fillNode.label + i.toString()}
          >
            <TileSet>
              {fillNode.node.options!.map((fillOption) => (
                <ImageTileTriple
                  /* eslint-disable-next-line @eslint-react/no-array-index-key */
                  key={fillOption.id + i}
                  path={getAsset(`icons/fills/${fillOption.id}.png`)}
                  title={fillOption.name}
                  isSelected={fillNode.node.value === fillOption.id}
                  onSelected={() => {
                    dispatch(change(fillNode.node.keyPath, fillOption.id))
                    /* set the default values for fill colors */
                    fillNode.colors.forEach((colorNode, j) => {
                      dispatch(
                        change(
                          colorNode.node.keyPath,
                          (fillOption as FillOption | undefined)?.props
                            ?.defaults[`color${j + 1}`],
                        ),
                      )
                    })
                  }}
                />
              ))}
            </TileSet>
            {isSomeFillColorAvailable(fillNode) && (
              <TileSet>
                {fillNode.colors.map(
                  (colorNode, j) =>
                    colorNode.node.isAvailable &&
                    /* only render each color if the particular fill uses it */
                    `color${j + 1}` in getSelectedFillProps(fillNode) && (
                      <ColorTile
                        /* eslint-disable-next-line @eslint-react/no-array-index-key */
                        key={colorNode.node.keyPath + i + j}
                        label={colorNode.label}
                        colors={colorNode.node.options as ColorOption[]}
                        color={colorNode.node.object as ColorOption}
                        onSelected={(color) => {
                          dispatch(
                            change(colorNode.node.keyPath, color?.id ?? null),
                          )
                        }}
                        setAlert={setAlert}
                        isColorToneEnabled={features.colorTone}
                      />
                    ),
                )}
              </TileSet>
            )}
          </TileSection>
        ))}

      {teamNameTextNode.isAvailable && (
        <TextTile
          title="Team Name"
          textNode={nodes['teamName.text'] as TextNode}
          colorNode={nodes['teamName.color']}
          fontNode={nodes['teamName.font'] as FontNode}
          outline1ColorNode={nodes['teamName.outline1Color']}
          outline2ColorNode={nodes['teamName.outline2Color']}
          scaleFactorNode={nodes['teamName.scaleFactor']}
          setAlert={setAlert}
          isColorToneEnabled={features.colorTone}
          isOutlineEnabled={features.outline}
        />
      )}

      {isRosterEnabled && (
        <RosterTile
          nodes={nodes}
          setAlert={setAlert}
          isColorToneEnabled={features.colorTone}
          isOutlineEnabled={features.outline}
        />
      )}

      {!isRosterEnabled &&
        features.playerName &&
        nodes['players.roster.1.name']?.isAvailable && (
          <TextTile
            title="Player Name"
            textNode={nodes['players.roster.1.name'] as TextNode}
            colorNode={nodes['players.nameStyle.color']}
            fontNode={nodes['players.nameStyle.font'] as FontNode}
            outline1ColorNode={nodes['players.nameStyle.outline1Color']}
            outline2ColorNode={nodes['players.nameStyle.outline2Color']}
            scaleFactorNode={nodes['players.nameStyle.scaleFactor']}
            setAlert={setAlert}
            isColorToneEnabled={features.colorTone}
            isOutlineEnabled={features.outline}
          />
        )}

      {!isRosterEnabled &&
        features.playerNumber &&
        nodes['players.roster.1.number']?.isAvailable && (
          <TextTile
            title="Player Number"
            textNode={nodes['players.roster.1.number'] as TextNode}
            colorNode={nodes['players.numberStyle.color']}
            fontNode={nodes['players.numberStyle.font'] as FontNode}
            outline1ColorNode={nodes['players.numberStyle.outline1Color']}
            outline2ColorNode={nodes['players.numberStyle.outline2Color']}
            playerNumberFrontScaleFactorNode={
              nodes['players.numberStyle.scaleFactor.front']
            }
            playerNumberBackScaleFactorNode={
              nodes['players.numberStyle.scaleFactor.back']
            }
            setAlert={setAlert}
            isColorToneEnabled={features.colorTone}
            isOutlineEnabled={features.outline}
          />
        )}

      {teamLogoNode.isAvailable && (
        <TileSection section="Team Logo">
          <TileSet>
            <ImageUpload
              imageUrl={teamLogoImageUrl}
              accept="image/svg+xml"
              onChanged={(file) => {
                dispatch(change(teamLogoNode.keyPath, file))
              }}
              onRemoved={() => {
                dispatch(change(teamLogoNode.keyPath, null))
              }}
              setAlert={setAlert}
              kindOfFile="SVG image"
            />
          </TileSet>
        </TileSection>
      )}

      {!isRosterEnabled && playerSize.visibleOptions!.length > 1 && (
        <TileSection section="Size">
          <TileSet>
            <SelectTile
              options={playerSize.visibleOptions!}
              value={playerSize.value as string}
              onChange={(value) => {
                dispatch(change(playerSize.keyPath, value))
              }}
            />
          </TileSet>
        </TileSection>
      )}

      <Filler className="h-[120px]" />
    </div>
  )
}

export default Sidebar
